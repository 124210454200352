export class Constants {
  static readonly LOCAL_ENV: string = 'LOCAL';
  static readonly DEV_ENV: string = 'DEV';
  static readonly TEST_ENV: string = 'TEST';
  static readonly PROD_ENV: string = 'PRODUCTION';
  static readonly ASSORTMENT: string = "Assortment";
  static readonly SALES: string = "Sales";
  static readonly IMPERSONATION_USER_EMAIL_LOCAL_STORAGE_KEY: string = "impersonated_user_email";
  static readonly FUNCTION_NAME_IMPERSONATE_USER: string = "ImpersonateUser";
  static readonly FEATURE_FLAG_SALES_PERCENT_PM_SELECTION: string = "selection-view-sales-percentpm"
  static readonly FEATURE_FLAG_PLANNING_VIEW_DATEPICKER: string = "planning-view-datepicker"
  static readonly PUBLISH_DISABLE_FOR_SEASONS: string = "publish-disable-for-seasons"
  static readonly PUBLISH_DISABLE_FOR_BRANDS: string = "publish-disable-for-brands"
  static readonly ADDREMOVEMSEK: string = "ADDREMOVEMSEK";
  static readonly ADDMOVESMSEK: string = "ADDMOVESMSEK";
  static readonly BUILDNUMBER: string = "build_number";
  static readonly VERSIONNUMBER: string = "version_number";
  static readonly TOOLBARBGIMG: string = "toolbar_bg_img";
  static readonly NAVMESSAGE: string = "nav_message";
  static readonly TSEKTOMSEK: number = 1000;
  static readonly USER_CONFIG: any = 'user-config';
  static readonly BRAND_STRUCTURE_NAME: string = 'brand';
  static readonly REFRESHCACHETIMESTAMP: string = 'refresh_cache_timestamp';
  //Changes in the end week of Timeline view should be updated in the utils.service.ts getSeasonViewType method and also in SeasonHelper.cs GetSeasonViewConfigurations method
  static readonly TIMELINEVIEW1_ENDWEEK: string = '04';
  static readonly TIMELINEVIEW2_ENDWEEK: string = '20';
  static readonly TIMELINEVIEW3_ENDWEEK: string = '32';
  static readonly TIMELINEVIEW4_ENDWEEK: string = '48';
  static readonly AnplanEnabledSectionErrorMessage: string = "This section is not enabled in OmniTDP instead use Anaplan";
}
